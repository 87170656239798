import React, {useEffect} from 'react';
import './App.scss';
import Home from './Pages/Home/Home';
import TNC from './Pages/TNC/TNC';
import FAQ from './Pages/FAQ/FAQ';
import RequestInstall from './Pages/RequestInstall/RequestInstall';
import RecoverPassword from './Pages/RecoverPassword/RecoverPassword';
import ConfirmedAccount from './Pages/ConfirmedAccount/ConfirmedAccount';
import Payment from './Pages/Payment/Payment';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import scrollToComponent from 'react-scroll-to-component';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';

const Connector = ()=>{
  return (
    <div className="main">
      <Navbar/>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/solicitud_instalacion">
          <RequestInstall />
        </Route>
        <Route path="/preguntas_frecuentes">
          <FAQ />
        </Route>
        <Route path="/terminos_y_condiciones">
          <TNC />
        </Route>
        <Route path="/recuperar_contrasena/:token">
          <RecoverPassword />
        </Route>
        <Route path="/cuenta_confirmada">
          <ConfirmedAccount />
        </Route>
        <Route path="/payment/:idOrder/:tokenUser">
          <Payment />
        </Route>
      </Switch>
      <Footer/>
    </div>
  );
}

export default Connector;
