import React from 'react';
import './Usuarios.scss';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import VisibilitySensor from 'react-visibility-sensor';
import {
  useHistory
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const Usuarios =  React.forwardRef((props, ref)=>{
  const history = useHistory();
  const classes = useStyles();

  const changeVisibility = (isVisible)=>{
    if(isVisible){
      history.push('/?section=usuarios&moving=true')
    }
  }

  const goToRequestInstall = ()=>{

  }

  return (
    <VisibilitySensor partialVisibility={true} onChange={changeVisibility}>
      <div ref={ref} className="usuarios-container">
        <div className="title-usuarios">Beneficios para Usuarios LavaBox</div>
        <div className="container-grid">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} className="container-card">
              <img src="/assets/time.png" className="image-card"></img>
              <div className="title-card">Deja tu ropa en nuestros casilleros inteligentes y olvídate del resto</div>
            </Grid>
            <Grid item xs={12} sm={4} className="container-card">
              <img src="/assets/calendar.png" className="image-card"></img>
              <div className="title-card">Nuestros casilleros inteligentes están disponibles 24/7 en tu edificio</div>
            </Grid>
            <Grid item xs={12} sm={4} className="container-card">
              <img src="/assets/money.png" className="image-card"></img>
              <div className="title-card">Contamos con excelentes precios y promociones</div>
            </Grid>
          </Grid>
        </div>
      </div>
    </VisibilitySensor>
  );
});

export default Usuarios;
