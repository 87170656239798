import React from "react";
import "./ConfirmedAccount.scss";
import { Link } from "react-router-dom";

const ConfirmedAccount = () => {
  const goToDownloadAndroid = () => {
    window.open(process.eventNames.REACT_APP_ANDROID_STORE_URL);
  };

  const goToDownloadIOS = () => {
    window.open(process.env.REACT_APP_IOS_STORE_URL);
  };

  return (
    <div className="confirmed-account-container">
      <img className="logo-confirmed" src="/assets/logo.png"></img>
      <div className="subtitle">
        ¡Bienvenido a Lavabox! Ingresa a nuestra app y comienza a disfrutar de
        nuetros servicios 😊
      </div>
      <Link onClick={goToDownloadIOS}>
        <img
          src="/assets/download-ios.png"
          className="logo-download logo-ios"
        />
      </Link>
      <Link onClick={goToDownloadAndroid}>
        <img src="/assets/download-android.png" className="logo-download" />
      </Link>
    </div>
  );
};

export default ConfirmedAccount;
