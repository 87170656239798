import React, { useState, useEffect } from "react";
import "./Payment.scss";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router-dom";
import CreditCardInput from "react-credit-card-input";
import URL_API from "../../Consts/URL_API";

import CircularProgress from "@material-ui/core/CircularProgress";

const Request = React.forwardRef((props, ref) => {
  const history = useHistory();
  const [cc, setCC] = useState("");
  const [name, setName] = useState("");
  const [cvv, setCVV] = useState("");
  const [mmyy, setMMYY] = useState("");
  const [email, setEmail] = useState("");
  const [installments, setInstallmentst] = useState(1);
  const [typeDoc, setTypeDoc] = useState("DNI");
  const [payMID, setPayMID] = useState("");
  const [numDoc, setNumDoc] = useState("");
  const [valueInsta, setValueInsta] = useState([]);
  const [order, setOrder] = useState(undefined);
  const [creationDate, setCreationDate] = useState(undefined);
  const [promoCode, setPromoCode] = useState(undefined);
  const [typePromoCode, setTypePromoCode] = useState(undefined);
  const [doSUbmit, setDoSubmit] = useState(false);
  const [form, setForm] = useState();
  const [price, setPrice] = useState(0);
  const [usedCoupon, setUsedCoupon] = useState(false);
  const [coupon, setCoupon] = useState(undefined);

  const [process, setProcess] = useState(false);
  const { idOrder, tokenUser } = useParams();

  //event change card number
  const changeCardNumber = (e) => {
    setCC(e.target.value);
    guessPaymentMethod(cc);
  };

  //verify cardNumber
  const guessPaymentMethod = (cardNumber) => {
    if (cardNumber.length >= 6) {
      let bin = cardNumber.substring(0, 6);
      window.Mercadopago.getPaymentMethod(
        {
          bin: bin,
        },
        setPaymentMethod
      );
    }
  };

  // get method type
  const setPaymentMethod = (status, response) => {
    if (status === 200) {
      let paymentMethodId = response[0].id;
      //console.log(paymentMethodId);
      setPayMID(paymentMethodId);
      getInstallments();
    } else {
      swal({ text: "Error en el metodo de pago", button: false });
    }
  };

  const getInstallments = () => {
    window.Mercadopago.getInstallments(
      {
        payment_method_id: payMID,
        amount: price / 100,
      },
      function (status, response) {
        if (status === 200) {
          const tmp = [];
          //console.log(response)
          response[0].payer_costs.forEach((i) => {
            tmp.push({ text: i.recommended_message, value: i.installments });
          });
          setValueInsta(tmp);
        }
      }
    );
  };

  const doPay = (event) => {
    event.preventDefault();
    setProcess(true);
    if (!doSUbmit) {
      var form = event.target;
      form.description = "Lavanderia";
      form.transaction_amount = price / 100;
      form.payment_method_id = payMID;
      //console.log(form);
      setForm(form);
      window.Mercadopago.createToken(form, sdkResponseHandler);
      return false;
    }
  };

  const sdkResponseHandler = async (status, response) => {
    if (status !== 200 && status !== 201) {
      //console.log(status, response)
      swal({ text: "Verifique su informacion", buttons: false });
      setProcess(false);
      setDoSubmit(false);
    } else {
      console.log(status, response);
      const data = {
        transaction_amount: price / 100,
        token: response.id,
        description: "Lavanderia",
        installments: parseInt(installments),
        payment_method_id: payMID,
        payer: {
          email: email,
        },
        orderId: idOrder,
      };

      //console.log(data);
      const res = await fetch(`${URL_API}/order/payMp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
        body: JSON.stringify(data),
      });

      setDoSubmit(true);

      const resJson = await res.json();
      console.log(resJson);
      if (resJson.status === 200 || resJson.status === 201) {
        swal({ text: "Transaccion exitosa", buttons: false });
        if (promoCode.length > 0) {
          const register = await RegisterUsedCoupon(coupon._id, typePromoCode);
          console.log(register);
        }
        history.push("/?section=about&moving=true");
      }
    }
  };

  const changeInstallments = (e) => {
    //console.log(e.target.value);
    setInstallmentst(e.target.value);
  };

  const changeCVV = (e) => {
    setCVV(e.target.value);
  };

  const changeMMYY = (e) => {
    console.log(e.target.value);
    setMMYY(e.target.value);
  };

  const changeName = (e) => {
    setName(e.target.value);
  };
  const changePromoCode = (e) => {
    setPromoCode(e.target.value);
  };

  // Get orde from API
  const getOrder = () => {
    console.log("Order ID and User Token> ", idOrder, tokenUser);
    fetch(`${URL_API}/order/${idOrder}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenUser}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          swal({
            text: "Lo sentimos','Hubo un error, intentar nuevamente en unos momentos",
            button: false,
          });
        }
      })
      .then((responseJson) => {
        if (responseJson) {
          setOrder(responseJson);
          setPrice(parseInt(responseJson.price));
          let creationDate = new Date(responseJson.createdAt);
          setCreationDate(
            `${creationDate.getDate()}/${
              creationDate.getMonth() - 1
            }/${creationDate.getFullYear()}`
          );
        }
      })
      .catch((error) => {
        swal({
          text: "Lo sentimos','Hubo un error, intentar nuevamente en unos momentos",
          button: false,
        });
      });
  };

  // Get user
  const GetUser = async (userId) => {
    let response = await fetch(`${URL_API}/user/${order._userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenUser}`,
      },
    });

    return await response.json();
  };

  // Get coupon from API
  const GetCoupon = async (couponCode, type) => {
    let url;
    switch (type) {
      case "admin":
        url = `${URL_API}/coupon/admin/${couponCode}`;
        break;
    }

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenUser}`,
      },
    });

    let data = await response.json();

    return data;
  };

  // Get coupon if used from api
  const GetUsedCoupon = async (couponId, type) => {
    let url;
    switch (type) {
      case "admin":
        url = `${URL_API}/coupon/admin/used/${couponId}`;
        break;
    }

    let response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenUser}`,
      },
    });
    let data = await response.json();
    return data;
  };

  // Register coupon used in api
  const RegisterUsedCoupon = async (couponId, type) => {
    let url;
    let data;
    switch (type) {
      case "admin":
        url = `${URL_API}/coupon/admin/used/`;
        data = {
          _couponId: couponId,
          usedDate: new Date(),
        };
        break;
    }

    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenUser}`,
      },
      body: JSON.stringify(data),
    });

    let responseJson = await response.json();
    return responseJson;
  };

  // launch coupon
  const launchCupon = async () => {
    if (promoCode === "HolaLavabox") {
      setTypePromoCode("admin");
      let coupon_ = await GetCoupon(promoCode, "admin");
      // console.log(coupon);
      setCoupon(coupon_[0]);
      if (coupon) {
        let date = new Date();
        console.log(coupon);
        if (
          date >= new Date(coupon.startDate) &&
          date <= new Date(coupon.endDate) &&
          coupon.status
        ) {
          if (price >= 5000) {
            const used = await GetUsedCoupon(coupon._id, "admin");
            if (used.length > 0) {
              swal({ text: "Ya uso este cupon. Ingrese otro", button: false });
            } else {
              setPrice(price - coupon.discount);
              setUsedCoupon(true);
            }
          } else {
            swal({ text: coupon.description, button: false });
          }
        } else {
          swal({ text: "Cupon Vencido", button: false });
        }
      } else {
        console.log("not coupon");
        swal({ text: "Cupon Invalido", button: false });
      }
    } else {
      console.log("coupon from user");
    }
  };

  useEffect(() => {
    window.Mercadopago.setPublishableKey(
      process.env.REACT_APP_MERCADO_PAGO_PUBLISHEABLE_KEY
    );
    getOrder();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-payment">
      <div className="container-card-data">
        <div className="service-data-container">
          <div className="title">Pago de Orden</div>
          <div className="description-service">
            <b>Fecha de Creación</b>
            <i>{creationDate}</i>
          </div>
          <div className="description-service">
            <b>Edificio</b>
            <i>{order?.building}</i>
          </div>
          <div className="description-service">
            <b>Distrito</b>
            <i>{order?.district}</i>
          </div>
          <div className="description-service">
            <b>Precio</b>
            <i>S/ {(price / 100)?.toFixed(2)}</i>
          </div>
          <div className="description">
            <b>Servicios</b>
          </div>
          {order?.services?.seco && (
            <div className="description">- Lavado al seco</div>
          )}
          {order?.services?.planchado && (
            <div className="description">- Lavado y planchado por peso</div>
          )}
          {order?.services?.edredones && (
            <div className="description">
              - Sábanas, cubrecamas y endredones
            </div>
          )}
          <div className="description-service">
            <b className="coupon-text">Cupon</b>
            <input
              name="promoCode"
              type="promoCode"
              className="text-field"
              placeholder="ex. YHYHGTFY"
              value={promoCode}
              onChange={(event) => {
                changePromoCode(event);
              }}
            />
            <button
              disabled={usedCoupon}
              className="button-validate"
              onClick={launchCupon}
            >
              Validar
            </button>
          </div>
        </div>
        <form
          onSubmit={(event) => {
            doPay(event);
          }}
        >
          <input
            type="hidden"
            id="cardNumber"
            data-checkout="cardNumber"
            value={cc}
          />
          <input
            type="hidden"
            id="cardExpirationMonth"
            data-checkout="cardExpirationMonth"
            value={mmyy.split("/")[0]}
          />
          <input
            type="hidden"
            id="cardExpirationYear"
            data-checkout="cardExpirationYear"
            value={(mmyy.split("/")[1] + "").trim()}
          />
          <input
            type="hidden"
            id="securityCode"
            data-checkout="securityCode"
            value={cvv}
          />
          <div>
            <CreditCardInput
              cardNumberInputProps={{ value: cc, onChange: changeCardNumber }}
              cardExpiryInputProps={{ value: mmyy, onChange: changeMMYY }}
              cardCVCInputProps={{ value: cvv, onChange: changeCVV }}
              fieldClassName="input"
            />
            <p>
              <label for="cardholderName">Nombre y apellido</label>
              <input
                type="text"
                id="cardholderName"
                data-checkout="cardholderName"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
            </p>
            <p>
              <label for="installments">Cuotas</label>
              <select
                id="installments"
                class="form-control"
                name="installments"
                value={installments}
                onChange={(event) => {
                  changeInstallments(event);
                }}
              >
                {valueInsta.map((i) => (
                  <option value={i.value}>{i.text}</option>
                ))}
              </select>
            </p>
            <p>
              <label for="docType">Tipo de documento</label>
              <select
                id="docType"
                data-checkout="docType"
                value={typeDoc}
                onChange={(event) => {
                  setTypeDoc(event.target.value);
                }}
              >
                <option value="DNI">DNI</option>
                <option value="CE">Carné de Extrangeria</option>
                <option value="RUC">RUC</option>
              </select>
            </p>
            <p>
              <label for="docNumber">Número de documento</label>
              <input
                type="text"
                id="docNumber"
                data-checkout="docNumber"
                value={numDoc}
                onChange={(event) => {
                  setNumDoc(event.target.value);
                }}
              />
            </p>
            <p>
              <label for="email"> Email </label>
              <input
                type="email"
                id="securityCode"
                data-checkout="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                onselectstart="return false"
                onpaste="return false"
                onCopy="return false"
                onCut="return false"
                onDrag="return false"
                onDrop="return false"
                autocomplete="off"
              />
            </p>
            <input
              type="hidden"
              name="payment_method_id"
              id="payment_method_id"
              value={payMID}
            />
          </div>
          {process ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />{" "}
            </div>
          ) : (
            <button className="button-pay" type="submit">
              Pagar S/ {(price / 100)?.toFixed(2)}
            </button>
          )}
        </form>
      </div>
    </div>
  );
});

export default Request;
