import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import { Drawer } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import getMobileOperatingSystem from "../../Helpers/DetectDeviceType";

import { Link } from "react-router-dom";

const Navbar = (props) => {
  const [drawerToggled, toggleDrawer] = useState(false);
  const [downloadAvailable, setDownload] = useState(false);
  const onToggleDrawer = () => {
    toggleDrawer(!drawerToggled);
  };

  const checkDownload = async (e) => {
    let os = await getMobileOperatingSystem();
    os = os.toLowerCase();
    if (os == "android" || os == "ios") {
      setDownload(true);
    } else {
      setDownload(false);
    }
  };

  const goToDownload = async (e) => {
    e.preventDefault();
    toggleDrawer(false);
    let os = await getMobileOperatingSystem();
    os = os.toLowerCase();
    if (os === "unknown") {
      window.open(process.env.REACT_APP_ANDROID_STORE_URL);
    } else if (os === "android") {
      window.open(process.env.REACT_APP_ANDROID_STORE_URL);
    } else if (os === "ios") {
      window.open(process.env.REACT_APP_IOS_STORE_URL);
    }
  };

  useEffect(() => {
    checkDownload();
  }, []);

  return (
    <div className="container-navbar">
      <Toolbar className="container-navbar-top">
        <Link
          className="container-logo-toolbar"
          to={"/?section=home&moving=false"}
        >
          <img src="/assets/logo_white.png" className="logo-toolbar" />
        </Link>
        <i className="material-icons menu" onClick={onToggleDrawer}>
          menu
        </i>
        {downloadAvailable && (
          <a href="#" onClick={goToDownload} className="link-nav-top">
            Descarga nuestra App
          </a>
        )}
        <Link to={"/?section=about&moving=false"} className="link-nav-top">
          ¿Cómo funciona?
        </Link>
        <Link
          to={"/?section=description&moving=false"}
          className="link-nav-top"
        >
          ¿Cómo instalar LavaBox?
        </Link>
        <Link to={"/solicitud_instalacion"} className="link-nav-top">
          Solicitar Instalación
        </Link>
      </Toolbar>
      <Drawer open={drawerToggled} onClose={onToggleDrawer}>
        <nav className="drawer-container">
          <Link
            onClick={onToggleDrawer}
            to={"/?section=home&moving=false"}
            className="container-logo-drawer"
          >
            <img className="logo-drawer" src="/assets/logo.png"></img>
          </Link>
          <Link
            to={"/?section=about&moving=false"}
            onClick={onToggleDrawer}
            className="link-drawer"
          >
            ¿Cómo funciona?
          </Link>
          <Link
            to={"/?section=description&moving=false"}
            onClick={onToggleDrawer}
            className="link-drawer"
          >
            ¿Cómo instalar LavaBox?
          </Link>
          <div className="divider">Beneficios</div>
          <Link
            to={"/?section=usuarios&moving=false"}
            onClick={onToggleDrawer}
            className="link-drawer"
          >
            Usuarios
          </Link>
          <Link
            to={"/?section=administrators&moving=false"}
            onClick={onToggleDrawer}
            className="link-drawer"
          >
            Administradores
          </Link>
          <div className="divider">Instalación</div>
          <Link
            to={"/solicitud_instalacion"}
            onClick={onToggleDrawer}
            className="link-drawer"
          >
            Solicitar Instalación
          </Link>
          <div className="divider">Información Importante</div>
          <Link
            to={"/?section=knowledge&moving=false"}
            onClick={onToggleDrawer}
            className="link-drawer"
          >
            Datos Importantes
          </Link>
          <Link
            to={"/preguntas_frecuentes"}
            onClick={onToggleDrawer}
            className="link-drawer"
          >
            Preguntas Frecuentes
          </Link>
          {downloadAvailable && (
            <a href="#" onClick={goToDownload} className="link-drawer">
              Descarga nuestra App
            </a>
          )}
        </nav>
      </Drawer>
    </div>
  );
};

export default Navbar;
