import React from 'react';
import './Description.scss';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import VisibilitySensor from 'react-visibility-sensor';
import {
  useHistory
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const Description =  React.forwardRef((props, ref)=>{
  const history = useHistory();
  const classes = useStyles();

  const changeVisibility = (isVisible)=>{
    if(isVisible){
      history.push('/?section=description&moving=true')
    }
  }

  const goToRequestInstall = ()=>{
    history.push('solicitud_instalacion')
  }

  return (
    <VisibilitySensor partialVisibility={true} onChange={changeVisibility}>
      <div ref={ref} className="description-container">
        <div className="title-description">¿Cómo instalar LavaBox?</div>
        <div className="subtitle-description">Disfruta de LavaBox en tu edificio en tan solo 3 simples pasos</div>
        <div className="container-grid">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} className="container-card">
              <div className="number-card">1</div>
              <img src="/assets/hotel.png" className="image-card"></img>
              <div className="title-card">Solicitud de instalación en edificio</div>
              <div className="subtitle-card">Llena el formulario y uno de nuestros agentes se comunicará contigo</div>
              <button id="request_2" className="button-request" onClick={goToRequestInstall}>Instala <b>gratis</b> Lavabox en tu edificio</button>
            </Grid>
            <Grid item xs={12} sm={4} className="container-card">
              <div className="number-card">2</div>
              <img src="/assets/closet.png" className="image-card"></img>
              <div className="title-card">Instalación de los casilleros inteligentes</div>
              <div className="subtitle-card">Instalación gratuita y promociones para los que viven o trabajan en el edificio</div>
            </Grid>
            <Grid item xs={12} sm={4} className="container-card">
              <div className="number-card">3</div>
              <img src="/assets/hour-glass.png" className="image-card"></img>
              <div className="title-card">Vive más fácil con LavaBox</div>
              <div className="subtitle-card">Descarga el app y comienza a disfrutar de todos los beneficios de LavaBox</div>
            </Grid>
          </Grid>
        </div>
      </div>
    </VisibilitySensor>
  );
});

export default Description;
