import React, { useState, useEffect } from "react";
import "./Request.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import VisibilitySensor from "react-visibility-sensor";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

import emailjs from "emailjs-com";

const Request = React.forwardRef((props, ref) => {
  const history = useHistory();
  const [relationship, setRelationship] = useState("Residente");
  const [optionRelationship, setOptionRelationship] = useState(false);
  const [buildingType, setBuildingType] = useState("Residencial");
  const [optionBuildingType, setOptionBuildingType] = useState(false);

  const toggleOptionRelationship = () => {};

  const handleChangeRelationship = (e) => {
    let newRelationsip = e.target.value;
    setRelationship(newRelationsip);
    if (newRelationsip == "Otro") {
      setOptionRelationship(true);
    } else {
      setOptionRelationship(false);
    }
  };

  const handleChangeBuildingType = (e) => {
    let buildingType = e.target.value;
    setBuildingType(buildingType);
    if (buildingType == "Otro") {
      setOptionBuildingType(true);
    } else {
      setOptionBuildingType(false);
    }
  };
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const submitForm = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      phone,
      propertyname,
      propertyaddress,
      district,
      napartments,
    } = e.target.elements;
    //console.log(name.value, lastname.value, email.value, phone.value, propertyname.value, propertyaddress.value, district.value, city.value, napartments.value);
    if (!name.value) {
      swal({ text: "Porfavor ingrese su nombre", buttons: false, timer: 4000 });
    } /*else if(!lastname.value){
      swal({text: "Porfavor ingrese su apellido", buttons: false, timer: 4000})
    }*/ else if (!email.value) {
      swal({ text: "Porfavor ingrese su correo", buttons: false, timer: 4000 });
    } else if (!phone.value) {
      swal({
        text: "Porfavor ingrese su número de teléfono",
        buttons: false,
        timer: 4000,
      });
    } /*else if(!lastname.value){
      swal({text: "Porfavor ingrese su apellido", buttons: false, timer: 4000})
    }*/ else if (!propertyname.value) {
      swal({
        text: "Porfavor ingrese el nombre de la propiedad",
        buttons: false,
        timer: 4000,
      });
    } else if (!propertyaddress.value) {
      swal({
        text: "Porfavor ingrese la dirección de la propiedad",
        buttons: false,
        timer: 4000,
      });
    } else if (!district.value) {
      swal({
        text: "Porfavor ingrese el distrito",
        buttons: false,
        timer: 4000,
      });
    } /*else if(!city.value){
      swal({text: "Porfavor ingrese la ciudad", buttons: false, timer: 4000})
    }*/ else if (!napartments.value) {
      swal({
        text: "Porfavor ingrese el número de apartamentos aproximado o oficinas aproximado",
        buttons: false,
        timer: 4000,
      });
    } else {
      const template_params = {
        from_name: "Formulario Web",
        to_name: "Lavabox",
        soli_name: name.value,
        /*soli_apellido: lastname.value,*/
        soli_correo: email.value,
        soli_telefono: phone.value,
        soli_tipo: relationship,
        resi_tipo: buildingType,
        resi_nombre: propertyname.value,
        resi_direccion: propertyaddress.value,
        resi_distrito: district.value,
        /*resi_cuidad: city.value,*/
        resi_n_departamentos: napartments.value,
      };
      console.log(template_params);
      //console.log(JSON.stringify(template_params, getCircularReplacer()))
      console.log(
        "REACT_APP_EMAILJS_SERVICE_ID",
        process.env.REACT_APP_EMAILJS_SERVICE_ID
      );
      console.log(
        "REACT_APP_EMAILJS_TEMPLATE_ID",
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID
      );
      console.log(
        "REACT_APP_EMAILJS_USER_ID",
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      try {
        emailjs
          .send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            template_params,
            process.env.REACT_APP_EMAILJS_USER_ID
          )
          .then(
            (res) => {
              console.log("Email successfully sent!", res);
              swal({
                text: "Su solicitud se ha enviado. Nos comunicaremos con usted en menos de 48 horas.",
                buttons: false,
                timer: 4000,
              });
              history.push("/?section=knowledge&moving=false");
            },
            (err) => {
              console.log("fail", err);
            }
          );
      } catch (error) {
        console.log(error);
      }
      /*
      POST TO GOOGLE FORMS
      */
      /*swal({text: "Su solicitud se ha enviado. Nos comunicaremos con usted en menos de 48 horas.", buttons: false, timer: 4000})
      history.push("/?section=knowledge&moving=false");*/
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="full-container">
      <div ref={ref} className="request-container">
        <div className="title-request">
          Refierenos tu edificio y nosotros nos encargamos del resto
        </div>
        <div className="subtitle-request">
          Disfruta del lujo de tener el servicio de lavandería y lavado al seco
          de LavaBox accesible las 24 horas, los 7 días de la semana. Llena el
          formulario y nos pondremos en contacto contigo.
        </div>
        <form onSubmit={submitForm} className="form-container">
          <div className="tag-part">Mis Datos</div>
          <Select
            className="select-container"
            value={relationship}
            onChange={handleChangeRelationship}
          >
            <MenuItem value={"Residente"}>Residente/Propietario</MenuItem>
            {/*<MenuItem value={'Propietario'}>Soy Propietario</MenuItem>*/}
            <MenuItem value={"Administrador"}>Administrador</MenuItem>
            {/*<MenuItem value={'Otro'}>Otro</MenuItem>*/}
          </Select>
          {/*{optionRelationship &&
            <input className="input-form" placeholder="Relación con el edificio a recomendar" required></input>
          }*/}
          <input
            name="name"
            type="name"
            className="input-form"
            placeholder="Nombre y Apellido"
            required
          ></input>
          {/*<input name="lastname" type="lastname" className="input-form" placeholder="Apellido" required></input>*/}
          <input
            name="email"
            type="email"
            className="input-form"
            placeholder="Correo"
            required
          ></input>
          <input
            name="phone"
            type="tel"
            className="input-form"
            placeholder="Número de teléfono"
            required
            minlength={6}
          ></input>

          <div className="tag-part">Datos del edificio</div>
          <Select
            className="select-container"
            value={buildingType}
            onChange={handleChangeBuildingType}
          >
            <MenuItem value={"Residencial"}>Edificio Residencial</MenuItem>
            <MenuItem value={"Oficina"}>Oficinas</MenuItem>
            {/*<MenuItem value={'Otro'}>Otro</MenuItem>*/}
          </Select>
          {/*{optionBuildingType &&
            <input className="input-form" placeholder="Escribe el tipo de edificio" required></input>
          }*/}
          <input
            name="propertyname"
            type="propertyname"
            className="input-form"
            placeholder="Nombre del edificio"
            required
          ></input>
          <input
            name="propertyaddress"
            type="propertyaddress"
            className="input-form"
            placeholder="Dirección del edificio"
            required
          ></input>
          <input
            name="district"
            type="district"
            className="input-form"
            placeholder="Distrito"
            required
          ></input>
          {/*<input name="city" type="city" className="input-form" placeholder="Ciudad" required></input>*/}
          <input
            name="napartments"
            type="number"
            className="input-form"
            placeholder="Número de departamentos"
            required
          ></input>
          <button type="submit" className="button-request">
            SOLICITAR INSTALACIÓN
          </button>
        </form>
      </div>
    </div>
  );
});

export default Request;
