import React from 'react';
import './RecoverPassword.scss';
import {withRouter} from 'react-router';
import swal from 'sweetalert';

const RecoverPassword = ({history, match}) => {
	const {token} = match.params;

	const onSubmit = (e) => {
		e.preventDefault();
		swal({text: "Modificando contraseña", button: false});
		const {password, repeat_password} = e.target.elements;

		fetch(`${process.env.REACT_APP_API_URL}/auth/change_password/${token}`, {
			method: 'POST', headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({password: password.value, repeat_password: repeat_password.value})
		})
			.then((response) => {
				console.log('response', response)
				if (response.ok) {
					swal({text: "Contraseña modificada, porfavor ingresar a través de nuestra app", button: false, timer: 2000});
					history.push('/');
				} else {
					swal({text: "Lo sentimos, no se pudo actualizar la contraseña", button: false, timer: 2000});
				}
			})
			.catch((error) => {
				console.log(error);
				swal({text: "Lo sentimos, no se pudo actualizar la contraseña", button: false, timer: 2000});
			})
	}

	return (
		<div className="container-change-password">
			<div className="title-request">Cambio de Contraseña</div>
			<div className="subtitle-request">Ingresa una nueva tu contraseña para tu cuenta:</div>
			<form className="form-container" onSubmit={onSubmit}>
				<input className="input-form" name="password" type="password" placeholder="Una contraseña segura" required/>
				<input className="input-form" name="repeat_password" type="password" placeholder="Repite tu contraseña"
				       required/>
				<button type="submit" className="button-request">Cambiar contraseña</button>
			</form>
		</div>
	);
};

export default withRouter(RecoverPassword);
