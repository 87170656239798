import React from "react";
import "./Footer.scss";

import { Link } from "react-router-dom";

const Footer = () => {
  // const goToDownload = ()=>{
  //   let os = getMobileOperatingSystem();
  //   if(os == 'unknown'){
  //     window.open(`https://play.google.com/store?utm_source=emea_Med&utm_medium=hasem&utm_content=090514&utm_campaign=Evergreen&pcampaignid=MKT-EG-emea-pl-all-Med-hasem-py-Evergreen-090514-1%7cONSEM_kwid_43700007034273533&gclid=Cj0KCQiAm4TyBRDgARIsAOU75sqF-juw5e1YlRj0_2NdQUlm_hhv3JNTQpVMARRdDLKk42BC8tkkew8aAqEJEALw_wcB&gclsrc=aw.ds`);
  //   }else if(os == 'android'){
  //     window.open(`https://play.google.com/store?utm_source=emea_Med&utm_medium=hasem&utm_content=090514&utm_campaign=Evergreen&pcampaignid=MKT-EG-emea-pl-all-Med-hasem-py-Evergreen-090514-1%7cONSEM_kwid_43700007034273533&gclid=Cj0KCQiAm4TyBRDgARIsAOU75sqF-juw5e1YlRj0_2NdQUlm_hhv3JNTQpVMARRdDLKk42BC8tkkew8aAqEJEALw_wcB&gclsrc=aw.ds`);
  //   }else if(os == 'ios'){
  //     window.open(`https://www.apple.com/ios/app-store/`);
  //   }
  // }

  const goToDownloadAndroid = () => {
    window.open(process.env.REACT_APP_ANDROID_URL);
  };

  const goToDownloadIOS = () => {
    window.open(process.env.REACT_APP_IOS_URL);
  };

  return (
    <div className="footer-container">
      <Link to={"/?section=home&moving=false"}>
        <img src="/assets/logo_white.png" className="logo-footer" alt="" />
      </Link>
      <Link onClick={goToDownloadIOS}>
        <img
          src="/assets/download-ios.png"
          className="logo-download logo-ios"
          alt=""
        />
      </Link>
      <Link onClick={goToDownloadAndroid}>
        <img
          src="/assets/download-android.png"
          className="logo-download"
          alt=""
        />
      </Link>
      <Link id="solicitar" to="solicitud_instalacion" className="link-footer">
        Solicitar Instalación
      </Link>
      <Link id="faq" to="preguntas_frecuentes" className="link-footer">
        Preguntas frecuentes
      </Link>
      <Link id="tyc" to="terminos_y_condiciones" className="link-footer">
        Términos y Condiciones
      </Link>
      <div className="container-icons">
        <a
          href="https://www.facebook.com/Lavabox-lavandería-100226081623561/"
          target="_black"
          className="container-icon"
        >
          <img
            id="facebook"
            className="icon"
            src="/assets/facebook.png"
            alt=""
          />
        </a>
        <a
          href="https://instagram.com/Lavaboxlavanderia"
          className="container-icon"
          target="_black"
        >
          <img
            id="instagram"
            className="icon"
            src="/assets/instagram.png"
            alt=""
          />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=+14372467791"
          className="container-icon"
          target="_black"
        >
          <img
            id="whatsapp"
            className="icon"
            src="/assets/whatsapp.png"
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
