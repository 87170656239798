const questions = [
  {
    question: "Colocando tu primer pedido",
    answer: `
    ¡Gracias por elegir LavaBox! Para realizar su primer pedido siga los siguientes pasos: <br/><br/>
    1. Descargue la aplicación LavaBox a través de Apple App Store o Google Play, y regístrate mediante la aplicación indicando el edificio en el que se encuentra. <br/><br/>
    2. Coloque sus artículos en cualquier bolsa desechable.<br/><br/>
    3. Coloque la bolsa con sus artículos en un casillero disponible en su edificio, cierre la puerta del casillero, coloque una clave de cuatro dígitos y presione el botón verde. Asegúrese que la puerta del casillero quede cerrada. <br/><br/>
    4. Envíe su pedido a través de nuestra aplicación ingresando el número de casillero en el cual dejo sus prendas y seleccionando el tipo de pedido apropiado. Si lo desea, puede incluir instrucciones especiales para su pedido. <br/><br/>
    5. Cuando se recoja su orden, nuestro equipo se encargará de cuantificar tu pedido y te enviaremos un correo para realizar el pago mediante nuestra aplicación móvil. Su pedido será enviado de regreso una vez que realice el pago. <br/><br/>
    6. Dedícate a hacer lo que más te importa. ¡Estamos trabajando en tu orden! Tus artículos serán devueltos dentro de aproximadamente 72 horas, dependiendo de tu ubicación de residencia. Recibirás una notificación de “Ropa en casillero” por correo electrónico y en el App, indicando el casillero donde dejamos tu ropa limpia y la contraseña para desbloquearlo.`,
  },
  {
    question: "Cómo recojo mi pedido del casillero",
    answer: `Una vez que recibas la información de recojo de tu pedido, tenga en cuenta que es posible que tu pedido no se encuentre en el mismo casillero que eligió inicialmente y que el código que usó para bloquear el casillero también será diferente. Sigue los siguientes pasos: <br/><br/>
Siga los pasos a continuación: <br/><br/>
1. Diríjase al casillero con el número indicado en la información de recojo. <br/><br/>
2. Ingrese el código de cuatro dígitos enviado por correo electrónico y visible en el app. <br/><br/>
3. Presione el botón verde.<br/><br/>
4. Abra el casillero y retire sus prendas, deje el casillero abierto. <br/><br/>
Si no puede recuperar su pedido siguiendo las instrucciones, comuníquese con nosotros y nos encargaremos de solucionar su problema lo más rápido posible..`,
  },
  {
    question: "¿Cuándo recibirás tu pedido?",
    answer: `Ofrecemos un tiempo de respuesta de 72 horas, que varía en función de su residencia, día de la semana y hora límite de recojo. <br/><br/>
    Los fines de semana y feriados no cuentan para el cálculo de las 72 horas.<br/><br/>
    Por ejemplo, si dejas tus artículos el lunes en la tarde, el jueves debes de estar recibiéndolos en el casillero.<br/><br/>
    Tenga en cuenta que nuestro periodo de entrega de 72 horas excluye reparaciones y alteraciones, artículos para el hogar y artículos de cuidado especial como cuero, gamuza y piel.`,
  },
  {
    question: "¿Cómo agrego instrucciones especiales para mi pedido a través de la aplicación?",
    answer: `Primero inicia una nueva orden. Luego selecciona los servicios (Lavado al seco, lavado
al peso y ropa de cama). En esta pantalla hay un espacio para
ingresar instrucciones especiales. ¡Ingrésalo y selecciona Siguiente! Con esto tu orden
incluirá tus instrucciones especiales.`,
  img: '/assets/Comment.png'
  },
  {
    question: "¿Cómo agrego un cupón de descuento a mi pedido?",
    answer: `Una vez recibas el mensaje para realizar el pago de tu pedido, en la pantalla donde realizas el pago hay un espacio para ingresar el código promocional (Promo code). ¡Ingrésalo y selecciona Aplicar! Con esto tu orden incluirá el descuento al momento que selecciones ordenar. <br/> <br/>`,
  },
  {
    question: "Mi código de promoción no se aplica al enviar mi pedido",
    answer: `Si recibe una notificación de "Código de promoción no válido" cuando intenta enviar su pedido, comuníquese con nosotros enviando el código de promoción para que podamos verificar los términos del cupón y aplicar cualquier descuento a su cuenta.`,
  },
  {
    question: "¿Es LavaBox un servicio de lavado en seco y lavandería las 24 horas, los 7 días de la semana?",
    answer: `Somos un servicio de lavandería y lavado en seco accesible las 24 horas, los 7 días de la semana, lo que significa que se puede acceder a nuestros casilleros en cualquier momento para entrega y recojo. Nuestras plantas de lavado procesan pedidos dentro de las horas y días laborables y nuestros conductores trabajan en el horario comercial estándar.`,
},
{
  question: "¿Qué sucede si mi pedido se deja en un casillero durante un período prolongado de tiempo?",
  answer: `Entendemos que esto puede pasar, por lo que le enviaremos un mensaje recordándole el recojo de sus pendas. En el caso que necesitemos retirar su pedido del casillero, nos pondremos en contacto para coordinar la entregar de sus artículos.<br/>
  Nota: Si nosotros retiramos su pedido del casillero de Lavabox, nos comunicaremos por correo electrónico y teléfono para darle la oportunidad de reclamar sus pertenencias. Si no recibimos una respuesta dentro de los cuarenta días, sus artículos serán considerados olvidados y donados en su nombre.<br/>`,
},
{
  question: "Todos los casilleros están llenos en mi residencia, ¿puedo hacer un pedido?",
  answer: `¿Su edificio tiene recepción? Si la respuesta es sí, deje su pedido en la recepción en su bolsa y contáctenos para que podamos enviar un conductor para que recoja sus artículos lo antes posible.  <br/>
  Si su edificio no tiene recepción, comuníquese con nosotros y le enviaremos un conductor para que retire los pedidos que han estado ocupando casilleros durante un período prolongado. Lamentablemente, no podrá realizar un pedido hasta que haya casilleros disponibles. No deje ningún pedido fuera de los casilleros de LavaBox. No nos hacemos responsables de los artículos dañados o perdidos que no se coloquen en un casillero.`,
},
{
  question: "¿Puedo recibir paquetes de otros servicios en un casillero de LavaBox?",
  answer: `Todos los casilleros solo deben usarse para los servicios de LavaBox; un servicio de terceros nunca debe entregar paquetes de ningún tipo en nuestros casilleros. No somos responsables de los artículos dañados o perdidos fuera de nuestro negocio, y no seremos responsables de cubrir ningún costo.`,
},
{
  question: '¿Puedo solicitar una entrega urgente?',
  answer: 'Por el momento no se pueden garantizar entregas urgentes. Sin embargo, estamos trabajando para pronto incluir este servicio en todos los casilleros LavaBox.'
},
{
  question: "¿Cómo cancelo un pedido si ya coloqué mis artículos en el casillero de LavaBox?",
  answer: `<u>Su pedido aún está en el casillero:</u>:<br/><br/>
  Si decide cancelar su pedido después de colocar sus artículos en un casillero de LavaBox, puede recuperar su pedido (antes de que nuestros conductores estén en el edificio) ingresando el código de cuatro dígitos que usó para bloquear el casillero. Adicionalmente, cancele el pedido mediante la aplicación móvil.<br/><br/>
<u>Su pedido fue recogido por los conductores:</u><br/><br/>
Si nuestros conductores recogieron su pedido antes de que pudiera retirar sus artículos del casillero, comuníquese con nosotros lo antes posible para que podamos comunicarnos con nuestra planta y que le enviemos su pedido sin procesar. <br/><br/>
Tenga en cuenta que no podemos garantizar que sus artículos serán devueltos el mismo día o al día siguiente.`,
},
{
  question: "Estoy experimentando un problema con el casillero",
  answer: `Si tiene algún problema con el casillero, debido a que la cerradura no se cierra de manera segura, un atasco en la puerta o la limpieza de un casillero, comuníquese con nosotros con el número de casillero y la ubicación de la residencia para que podamos resolver el asunto lo más rápido posible.`,
},
{
  question: "Ingresé el número de casillero incorrecto para mi pedido",
  answer: `Si envía un pedido por error con el número de casillero incorrecto, escriba un comentario en su orden detallando el cambio para modificar el pedido.`,
},
{
  question: "Puse mis artículos en un locker LavaBox y recibí una notificación de que el locker no estaba disponible",
  answer: `Si pudo colocar sus artículos en un locker LavaBox pero recibió una notificación que
indica que el locker está siendo usado por otra persona, comuníquese con nosotros
para que podamos restablecer el locker y volver a enviar su pedido.`,
},
{
  question: "¿Qué tipo de orden seleccionar?",
  answer: `Lavado al seco es nuestro servicio usado más popular. Recomendamos este servicio para cualquier prenda que no se deba ser lavada utilizando una lavadora y secadora y que deba limpiarse por medio de vapor y devolverse en perchas. Los artículos comúnmente limpiados con este servicio son camisas, pantalones, ternos, vestidos, blusas y suéteres. <br/><br/>
  Recomendamos nuestro servicio de Lavado y Planchado al peso para la limpieza de sus artículos cotidianos, como ropa interior y ropa deportiva y ropa de diario. ¡Su ropa será cuidada profesionalmente y devuelta limpia, doblada y lista para guardar!<br/><br/>
  Para toda su ropa de cama, por favor utilice nuestro servicio de Sabanas y Cubrecamas. No aceptaremos ropa de cama en el servicio de lavado y planchado.<br/><br/>
  Nota: Sus camisas no necesariamente se limpiarán en seco. Puede que sean lavadas en un ciclo de lavado seguido de un proceso de secado y prensado a base de vapor, que es el método estándar de la industria para estos artículos.<br/><br/>
  Nosotros seguimos los estándares internacionales de cuidado de todos los artículos para asegurarnos de que reciban la atención adecuada.`
}

]

export default questions;
