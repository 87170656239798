import React from 'react';
import './Administrators.scss';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import VisibilitySensor from 'react-visibility-sensor';
import {
  useHistory
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const Administrators =  React.forwardRef((props, ref)=>{
  const history = useHistory();
  const classes = useStyles();

  const changeVisibility = (isVisible)=>{
    if(isVisible){
      history.push('/?section=administrators&moving=true')
    }
  }

  const goToRequestInstall = ()=>{

  }

  return (
    <VisibilitySensor partialVisibility={true} onChange={changeVisibility}>
      <div ref={ref} className="administrators-container">
        <div className="title-administrators">Beneficios para Administradores del edificio</div>
        <div className="container-grid">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} className="container-card">
              <img src="/assets/money.png" className="image-card"></img>
              <div className="title-card">Ofrece un servicio adicional sin esfuerzo ni gasto</div>
            </Grid>
            <Grid item xs={12} sm={4} className="container-card">
              <img src="/assets/heart.png" className="image-card"></img>
              <div className="title-card">Mejora la satisfacción de los residentes de tu edificio</div>
            </Grid>
            <Grid item xs={12} sm={4} className="container-card">
              <img src="/assets/growth.png" className="image-card"></img>
              <div className="title-card">Aumenta el valor de la propiedad con un servicio exclusivo</div>
            </Grid>
          </Grid>
        </div>
      </div>
    </VisibilitySensor>
  );
});

export default Administrators;
