import React from 'react';
import './About.scss';
import YouTube from 'react-youtube';
import VisibilitySensor from 'react-visibility-sensor';
import {
  useHistory
} from "react-router-dom";

const opts = {
  height: '390',
  width: '100%',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
const About =  React.forwardRef((props, ref)=>{
  const history = useHistory();

  const _onReady = (event)=>{
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const changeVisibility = (isVisible)=>{
    if(isVisible){
      history.push('/?section=about&moving=true')
    }
  }

  return (
    <VisibilitySensor partialVisibility={true} onChange={changeVisibility}>
      <div ref={ref} className="about-container">
        <div className="title-about">¿Cómo funciona?</div>
        <div className="container-video">
          <YouTube
            videoId="2f3c1TTXefg"
            opts={opts}
            onReady={_onReady}
          />
        </div>
      </div>
    </VisibilitySensor>
  );
});

export default About;
