import React from 'react';
import './Home.scss';
import VisibilitySensor from 'react-visibility-sensor';
import {
  withRouter,
  useHistory
} from "react-router-dom";

const Home = React.forwardRef((props, ref)=>{
  const history = useHistory();
  const changeVisibility = (isVisible)=>{
    if(isVisible){
      history.push('/?section=home&moving=true')
    }
  }

  const goToRequestInstall = (isVisible)=>{
    history.push('solicitud_instalacion')
  }

  return (
    <VisibilitySensor partialVisibility={true}  onChange={changeVisibility}>
      <React.Fragment>
        <div ref={ref} className="home-container">
          <div className="container-sticker">
            <div className="sticker-title">Casilleros inteligentes de lavado al seco y lavandería en tu edificio</div>
            <div className="sticker-subtitle">Una experiencia más limpia para edificios residenciales y oficinas</div>
          </div>
          <div className="layer"></div>
          <img className="logo-home" src="/assets/logo.png" />
          <button id="button_home" className="button-request" onClick={goToRequestInstall}>Instala <b>gratis</b> Lavabox en tu edificio</button>
        </div>
        <div className="container-announce">
          <div className="announce-title">Lockers de lavado al seco y lavandería en tu edificio</div>
          <div className="announce-subtitle">Una experiencia mas limpia para edificios residenciales y oficinas</div>
        </div>
      </React.Fragment>
    </VisibilitySensor>
  );
});

export default Home;
